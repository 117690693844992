<template>
  <div v-if="departments.length" :class="{'right-class': $i18n.locale === 'ar'}" class="calendar-container">
          <div class="row">
              <div class="col-6 dep-height">
                  <div :style="selectedDepartment.can_select_member == 1 ? 'display: flex;flex-direction: column;height: 100%;justify-content: space-between' : 'display: flex;flex-direction: column;height: 100%;justify-content: start'">
                      <div :class="{'right-class': $i18n.locale === 'ar'}" class="calendar-title">
                          {{ $t('Kiosk.rndvMultipleTitle') }}
                      </div>
                      <div>
                  <div :class="{'right-class': $i18n.locale === 'ar'}" class="second-title">
                      {{ $t('Kiosk.rndvType') }}
                  </div>
                <div class="departments-container">
                    <div :class="{'right-class': $i18n.locale === 'ar'}" class="row">
                        <div style="padding: 1vmin !important;" :class="{'col-4':departments.length > 2,'col-6':departments.length < 3}" v-for="(department, index) in departments" :key="index" >
                            <div class="department" @click="selectDepartment(department)" :class="{'selected-dep': selectedDepartment === department}">
                                <div v-if="department.translations.length" :class="{'right-class': $i18n.locale === 'ar'}" class="dep-label">
                                    JSON.parse(department.translations)[$i18n.locale]}
                                </div>
                                <div :class="{'right-class': $i18n.locale === 'ar'}" v-else class="dep-label">  {{department.name}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                  </div>
                  <div>
                  <div :class="{'right-class': $i18n.locale === 'ar'}" v-if="selectedDepartment && selectedDepartment.can_select_member == 1" class="second-title">
                    <span v-if="selectedDepartment.dep_members.length">{{ themeTemplate.allowCategory == 1 ? $t('Kiosk.deskNumber') : $t('Kiosk.agent') }}</span>
                  </div>

                  <div v-if="selectedDepartment.can_select_member == 1" style="padding-right: 10px" class="departments-container agents" >
                      <div :class="{'right-class': $i18n.locale === 'ar'}" v-if="selectedDepartment && selectedDepartment.dep_members.length" class="row">
                          <div style="padding: 0.5vmin !important;" :class="{'col-4':selectedDepartment.dep_members.length > 2,'col-6':selectedDepartment.dep_members.length < 3}" v-for="(agent, index) in selectedDepartment.dep_members" :key="index" >
                              <div class="department" @click="selectedAgent = agent, selectedItems.agent = selectedAgent" :class="{'selected-dep': selectedAgent === agent}">
                                  <div class="dep-label">
                                      {{agent.fname}} {{agent.lname}}
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  </div>
              </div>
              </div>
              <div ref="calendar" class="col-6 week-calendar">
                <LbrxSingleWeekCalendar @select-dates="selectDate" v-model="selectedItems" :themeTemplate="themeTemplate"></LbrxSingleWeekCalendar>
              </div>
          </div>
      <div class="nextButtonContainer">
          <lbrx-button id="rndvNextButton" v-model="isDisabled"
                       @clicked="validateDate" :box-shadow="'0px 4px 4px rgba(0, 0, 0, 0.25)'" :label="$t('Kiosk.nextButton')" :color="themeTemplate.textColor"
                       :background="themeTemplate.primaryColor" height="9vmin" width="35%" font-size="1.5"></lbrx-button>
      </div>
      <lbrx-kiosk-popup v-if="showPopup" @close="showPopup = false" v-model="showPopup" :closable="isClosable" :box-height="boxHeight" :content="boxContent" :icon="boxIcon" :text-color="boxTextColor" :box-background="boxBackGround" :icon-color="boxIconColor"></lbrx-kiosk-popup>

  </div>
</template>

<script>

import colorShader from "@/_helpers/color-shader";
import LbrxButton from "@/components/LbrxButton.vue";
import moment from "moment";
import {kioskService} from "@/_services";
import LbrxKioskPopup from "@/components/LbrxKioskPopup.vue";
import LbrxSingleWeekCalendar from "@/components/LbrxSingleWeekCalendar.vue";
export default {
    name:"LbrxBookingMultipleDep",
    components: {LbrxSingleWeekCalendar, LbrxKioskPopup, LbrxButton},
    data() {
      return {
          date:null,
          maxSlotsCount:0,
          selectedTime:null,
          times: [],
          calendarWidth: '',
          calendarHeight: '',
          loadingSlots: false,
          services: [],
          boxHeight:'40%',
          boxContent: '',
          boxIcon: 'loader',
          boxTextColor: '',
          isClosable: false,
          boxBackGround: "#FFFFFF",
          boxIconColor: this.themeTemplate.secondaryColor,
          showPopup: false,
          departments: [],
          selectedItems: {},
          selectedDepartment: null,
          selectedAgent: null,
      }
    },
    emits: ['validate'],
    props: {
        themeTemplate: {required: true, type:Object},
    },
    created() {
        this.departments = JSON.parse(window.localStorage.getItem("business")).departments;
        this.selectDepartment(this.departments[0]);
    },
    mounted() {
        this.updateElementHeight();
        window.addEventListener('resize', this.updateElementHeight); // Optional: Update height on window resize
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.updateElementHeight);
    },
    methods: {
        updateElementHeight() {
            if (this.$refs.calendar) {
                this.calendarHeight = this.$refs.calendar.offsetHeight;
            }
        },
        selectDepartment(department) {
          console.log("here");
            this.selectedDepartment = department;
            this.selectedItems.department = this.selectedDepartment
            if(this.selectedDepartment.dep_members.length)
                this.selectedAgent = this.selectedDepartment.dep_members[0];
            else {
                this.selectedAgent = null;
            }
            this.selectedItems.agent = this.selectedAgent;
        },
        showErrorPopup(errorText) {
            this.showPopup = false;
            this.boxIcon = 'error';
            this.isClosable = true;
            this.boxContent = errorText;
            this.boxTextColor = '#EB5757'
            this.boxBackGround = "#FFFFFF";
            this.boxIconColor = '#EB5757';
            this.boxHeight = "40%";
            this.showPopup = true;
        },
        selectDate(date) {
          this.selectedTime = date.time;
          this.date = date.date;
        },
        validateDate() {
            if(this.selectedTime === null || this.date === null) {
                this.showErrorPopup(this.$t('Kiosk.invalidDate'));
            }
            else {
                this.date = moment(this.date).format("YYYY-MM-DD");
                const dt = {};
                dt.date = this.date;
                dt.time =  this.selectedTime.slot;
                const  data = {};
                data.date = dt;
                data.department = this.selectedDepartment;
                data.agent = this.selectedAgent;
                this.$emit('validate', data);
            }
        },
      getAvailableTimes(dt) {
          this.times = [];
          this.loadingSlots = true;
          this.date = moment(dt).format("YYYY-MM-DD");
          const payload = {business_id: JSON.parse(window.localStorage.getItem('business')).id, date: moment(this.date).locale('en').format('YYYY-MM-DD'), department_id: JSON.parse(window.localStorage.getItem('business')).departments[0].id, user: null}
          kioskService.getAvailableSlots(payload)
              .then((res)=>{
                  console.log(res);
                  this.times = res.slots
                  this.loadingSlots = false;
              })
              .catch((err)=>{
                  console.log(err);
                  this.loadingSlots = false;
              })

      },
      selectTime(time) {
          if(!time.active)
              return;
          if(time === this.selectedTime) {
              this.selectedTime = null;
              return;
          }
          this.selectedTime = time;
      }
    },
    computed: {
        hours() {
            const hours = [];
            let j = 0;
            hours[j] = [];
            hours[j].times = [];
            hours[j].times.push(this.times[j]);
            for(let i=1;i<this.times.length;i++) {
                if(this.times[i].slot.split(":")[0] === hours[j].times[hours[j].times.length-1].slot.split(":")[0]) {
                    hours[j].times.push(this.times[i]);
                    if(this.maxSlotsCount < hours[j].times.length)
                        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                        this.maxSlotsCount = hours[j].times.length;
                } else {
                    if(this.maxSlotsCount < hours[j].times.length)
                        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                        this.maxSlotsCount = hours[j].times.length;
                    j++;
                    hours[j] = [];
                    hours[j].times = [];
                    hours[j].times.push(this.times[i]);
                }
            }
            console.log(hours);
          return hours;
        },
        isDisabled() {
            return this.modelValue || this.loadingSlots;
        },
        getBackground() {
            return this.themeTemplate.booking.background;
        },
        getCalendarBg() {
            return this.themeTemplate.booking.calendarBackground;
        },
        getTitleColor() {
            return this.themeTemplate.booking.titleColor;
        },
        getCalendarBackground() {
            return this.themeTemplate.booking.calendarBackground;
        },
        getSubColor() {
            return this.themeTemplate.booking.subColor;
        },
        getTextColor() {
            return this.themeTemplate.booking.textColor;
        },
        getCalendarSlotBackground() {
            return this.themeTemplate.booking.calendarSlotBackground;
        },
        getTimeSlotBackground() {
            return this.themeTemplate.booking.timeSlotBackground;
        },
        singleSlotsWidth() {
            return 85/this.maxSlotsCount+'%';
        },
        getSecondaryColor() {
            return this.themeTemplate.secondaryColor;
        },
        getPrimaryColor() {
            return this.themeTemplate.primaryColor;
        },
        myElementHeight() {
            return this.calendarHeight+"px";
        }
    },
}
</script>

<style scoped>
.calendar-container{
    padding: 5vmin 2.5vmin 5vmin 2vmin;
    width: 100%;
    height: 96%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: v-bind(getBackground);
}
.calendar-title{
    font-family: 'Exo 2',serif;
    font-style: normal;
    font-weight: 400;
    font-size: 2.6vw;
    line-height: 3vw;
    text-align: left;
    /* identical to box height */
    color: v-bind(getTitleColor);
}
.departments-container{
    margin-top: 2vmin;
    width: 100%;
}
.agents{
    min-height: 30vh;
    max-height: 30vh;
    padding-right: 0.4vmin;
    overflow-y: auto;
    overflow-x: hidden;
}
.department{
    cursor: pointer;
    display: flex;
    width: 100%;
    height: 7vh;
    background: v-bind(getSubColor);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding-left:0.1vmin;
    padding-right:0.1vmin;
    margin-bottom: 1.5vmin;
    text-align: center;
}
.selected-dep{
    background: v-bind(getSecondaryColor) !important;
}
.selected-dep .dep-label {
    color: v-bind(getTextColor) !important;
}
.dep-label{
    font-family: 'Exo 2',serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.5vw;
    line-height: 26px;
    color: v-bind(getTextColor);
    margin: auto;
}
.second-title{
    margin-top: 3vmin;
    font-family: 'Exo 2',serif;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    height: 25px;
    line-height: 2vw;
    text-align: left;
    color: v-bind(getSubColor);

}
.nextButtonContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: -6vmin;
}
.week-calendar{
    background: v-bind(getCalendarBg);
    height: 67vh !important;
}
.dep-height {
    height: v-bind(myElementHeight); 
    overflow-y: auto;
}
</style>
