<template>
  <div v-if="!showCreateCustomer" class="printTicketBox">
    <lbrx-kiosk-header :themeTemplate="themeTemplate" :logo="themeTemplate.header.logo"
                       :hasBorderRadius="themeTemplate.header.borderRadius" v-model="isDisabled"
                       :primaryColor="themeTemplate.primaryColor" :secondaryColor="themeTemplate.secondaryColor"
                       :text-color="themeTemplate.textColor"
                       :header-background="themeTemplate.header.headerBackground"></lbrx-kiosk-header>
    <div class="mainContainer">
      <div v-if="!directPrint && !showBarcode" class="row" style="width: 100%"
           :class="{'center-content': !phoneNumberAllowed}">
        <div class="col-6 right-container"
             :style="{'border-right': phoneNumberAllowed ? '1px solid ' + getTextColor: ''}">
          <div class="anon-container">
            <div>
              <div :class="{'right-class': $i18n.locale === 'ar'}" v-if="!selectedDate"
                   :style="{'margin-top': noAnonym ? '30%': ''}" class="printTicketTitle">
                {{ $t('Kiosk.inputNumberTitleQueue') }}
              </div>
              <div :class="{'right-class': $i18n.locale === 'ar'}" v-else :style="{'margin-top': noAnonym ? '30%': ''}"
                   class="printTicketTitle">
                {{ $t('Kiosk.inputNumberTitleBooking') }}
              </div>
              <div :class="{'right-class': $i18n.locale === 'ar'}" class="agreement">
                {{ $t('Kiosk.acceptTermsFirstPart') }} <span
                  :style="{'color': themeTemplate.printTicket.secondaryTextColor}">“{{ $t('Kiosk.sendButton') }}”</span>{{
                  $t('Kiosk.acceptTermsSecondPart')
                }}
              </div>
            </div>
            <div v-if="!noAnonym" class="or-div">
              {{ $t('Kiosk.orText') }}
            </div>
            <lbrx-anonymous-ticket-print v-if="!noAnonym" @cancel_idle="$emit('cancel_idle')"
                                         @start_idle="$emit('start_idle')" @sending="$emit('sending')"
                                         @send-ticket="sendAnonymTicket" :cancel-auto-anonym="cancelAutoAnonym"
                                         v-model="isDisabled" :themeTemplate="themeTemplate"
                                         :kiosk_configJson="kiosk_configJson"
                                         :kiosk_config="kiosk_config"></lbrx-anonymous-ticket-print>
          </div>
          <div style="bottom: 0 !important;">
            <lbrx-button id="backButton" v-model="isDisabled"
                         :color="!noAnonym ? themeTemplate.textColor: themeTemplate.primaryColor"
                         :border="'1px solid '+ (!noAnonym ? themeTemplate.textColor: themeTemplate.primaryColor)"
                         :label="$t('Kiosk.backButton')" @clicked="cancel()" :background="'transparent'" width="90%"
                         :height="'10vmin'" font-size="1.5"></lbrx-button>
          </div>
        </div>
        <div v-if="phoneNumberAllowed" class="col-6 left-container">
          <lbrx-phone-number-input ref="lbrxPhoneNumberInput" @cancelAutoAnonymous="cancelAutoAnonymous"
                                   v-model="isDisabled" @send-ticket="sendTicketWithNumber" :noAnonym="noAnonym"
                                   :themeTemplate="themeTemplate"></lbrx-phone-number-input>
        </div>
      </div>
      <div v-else style="width: 100%;">

        <div v-if="!directPrint" class="row" style="width: 100%" :class="{'center-content': !phoneNumberAllowed}">
          <div class="col-6 right-container"
               :style="{'border-right': phoneNumberAllowed ? '1px solid ' + getTextColor: ''}">
            <div class="anon-container" style="height: 64.2vh !important">
              <div>
                <div :class="{'right-class': $i18n.locale === 'ar'}" :style="{'margin-top': noAnonym ? '30%': ''}"
                     class="printTicketTitle">
                  {{ $t('Kiosk.BarCodeScan') }}
                </div>
                <div :class="{'right-class': $i18n.locale === 'ar'}" class="agreement" style="font-size: 28px;">
                  {{ $t('Kiosk.GetTicket') }}
                </div>
                <div :class="{'right-class': $i18n.locale === 'ar'}" class="agreement" style="margin: 0;">
                  {{ $t('Kiosk.Step1') }}
                </div>
                <div :class="{'right-class': $i18n.locale === 'ar'}" class="agreement" style="margin: 0;">
                  {{ $t('Kiosk.Step2') }}
                </div>
                <div :class="{'right-class': $i18n.locale === 'ar'}" class="agreement" style="margin: 0;">
                  {{ $t('Kiosk.Step3') }}
                </div>
              </div>
              <div style="background-color: #ffffff; width: fit-content; margin: 20px auto; padding: 10px 40px;">
                <img src="/barcode.gif" style="width: 25vw; height: 25vh;" alt="">
              </div>

            </div>
            <div style="bottom: 0 !important;">
              <lbrx-button id="backButton" v-model="isDisabled"
                           :color="!noAnonym ? themeTemplate.textColor: themeTemplate.primaryColor"
                           :border="'1px solid '+ (!noAnonym ? themeTemplate.textColor: themeTemplate.primaryColor)"
                           :label="$t('Kiosk.backButton')" @clicked="cancel()" :background="'transparent'" width="90%"
                           :height="'10vmin'" font-size="1.5"></lbrx-button>
            </div>
          </div>
          <div class="col-6 left-container">
            <lbrx-phone-number-input ref="lbrxPhoneNumberInput" @cancelAutoAnonymous="cancelAutoAnonymous"
                                     @pushCustomer="sendTicketBarcode" :codeStyle="true" v-model="isDisabled"
                                     @send-ticket="sendTicketWithNumber" :noAnonym="noAnonym"
                                     :themeTemplate="themeTemplate"></lbrx-phone-number-input>
          </div>
        </div>

      </div>
    </div>
    <div class="footer-container">
      <the-footer v-if="themeTemplate.footer.showFooter" :color="themeTemplate.textColor" v-model="onLine"
                  :background="themeTemplate.footer.footerBackground"></the-footer>
    </div>
    <lbrx-kiosk-popup v-if="showPopup" @close="showPopup = false" v-model="showPopup" :closable="isClosable"
                      :box-height="boxHeight" :content="boxContent" :icon="boxIcon" :text-color="boxTextColor"
                      :box-background="boxBackGround" :icon-color="boxIconColor"></lbrx-kiosk-popup>
    <lbrx-kiosk-popup v-if="showTermsAndConditions" @validateTerms="continueProcess"
                      @not-validated="showTermsAndConditions = false" :confirmation-required="true" :terms="true"
                      @close="showTermsAndConditions = false" v-model="showTermsAndConditions" :closable="true"
                      :box-height="'80%'" :box-background="boxBackGround"></lbrx-kiosk-popup>
    <lbrx-kiosk-popup v-if="showBookingPopup" :confirmation-required="false" @bookingDone="this.$emit('bookingDone')"
                      :terms="false" :bookingConfirmation="true" @close="showBookingPopup = false"
                      v-model="showBookingPopup" :closable="true" :box-height="'34%'"
                      :box-background="boxBackGround"></lbrx-kiosk-popup>
    <lbrx-kiosk-popup v-if="showSimConfirmation" @close="showSimConfirmation = false" v-model="showSimConfirmation"
                      :phoneNumberConfirmation="true" :closable="isClosable" :box-height="'auto'" :content="boxContent"
                      :icon="boxIcon" :text-color="boxTextColor" :box-background="boxBackGround"
                      :icon-color="boxIconColor"></lbrx-kiosk-popup>
    <lbrx-kiosk-ticket-popup class="no-print" @resetAll="resetAll" v-if="showTicketPopup && currentTicket"
                             @close="showTicketPopup = false" v-model="showTicketPopup"
                             :text-color="themeTemplate.textColor" :secondary-color="themeTemplate.secondaryColor"
                             :primary-color="themeTemplate.primaryColor" :ticket="currentTicket"
                             :booking="currentBooking"></lbrx-kiosk-ticket-popup>
  </div>
  <div v-else>
    <LbrxCreateCustomer @cancel="showCreateCustomer=false,$emit('sending')" :themeTemplate="themeTemplate"
                        @submit="validateNewCustomer"></LbrxCreateCustomer>
  </div>
  <!-- ticket template -->
  <div
      v-if="kiosk_config.ticket_style == 'Long'"
      id="ticketPrint"
      style="text-align: center"
  >
    <img
        id="imageid"
        :src="
          kiosk_configJson.photo != ''
            ? kiosk_configJson.photo
            : ''
        "
        style="width: 30%; margin-bottom: 10px"
    />
    <br/>
    <span style="font-size: 18px">{{ $t("Kiosk.Ticket.Welcome") }}</span
    ><br/>
    <span style="font-size: 22px; font-weight: bold">{{
        business_config.name
      }}</span
    ><br/>
    <hr/>
    <table style="width: 100%">
      <tr>
        <td style="text-align: left; width: 50%; font-size: 20px;">
          {{ ticket.checkinDateFormatted }}
        </td>
        <td style="text-align: right; width: 50%; font-size: 20px;">
          {{ ticket.checkinTimeFormatted }}
        </td>
      </tr>
    </table>

    <div class="seperator"></div>
    <span style="font-size: 54px; font-weight: bold">{{
        ticket.public_identifier
      }}</span>
    <div class="seperator"></div>
    <hr/>
    <table style="width: 100%">
      <tr>
        <td style="text-align: center; width: 50%">
            <span style="font-size: 32px; font-weight: bold">{{
                ticket.total_waiting
              }}</span
            ><br/>
          <span style="font-size: 20px">{{ $t("Kiosk.Ticket.Rank") }}</span>
        </td>
        <td style="text-align: center; width: 50%">
            <span style="font-size: 32px; font-weight: bold">{{
                ticket.waitingTimeFormatted
              }}</span
            ><br/>
          <span style="font-size: 20px">{{
              $t("Kiosk.Ticket.WaitingTime")
            }}</span>
        </td>
      </tr>
    </table>

    <hr/>
    <span style="font-size: 24px; font-weight: bold">{{
        $t("Kiosk.Ticket.Services")
      }}</span
    ><br/>
    <span
        v-for="(item, index) in ticket.services"
        :key="index"
        style="font-size: 20px"
    >
        {{ item.title }} <br/>
      </span>
    <footer id="footer">
      <vue-qrcode :value="qrCode" :options="{ width: 100}"></vue-qrcode>
      <br/>
      <!-- <span style="font-size: 12px">{{ $t("Kiosk.Ticket.ScanQR") }}</span
      ><br /> -->
      <span style="font-size: 16px">{{ $t("Kiosk.Ticket.ThankYouGoodBye") }}</span>
    </footer>
  </div>

  <div
      v-else
      id="ticketPrint"
      class="horizontal-ticket"
      style="text-align: center;"
  >
    <div class="row w-100 p-0 m-0" style="align-items: center;">
      <div class="col-6 w-50 welcome p-0 m-0">
        <img
            id="imageid"
            :src="
              kiosk_configJson.photo != ''
                ? kiosk_configJson.photo
                : ''
            "
            style="height: 100px; margin-bottom: 0.2cm; float: left !important"
        />
      </div>
      <div class="col-6 w-50 welcome p-0 m-0">
        <vue-qrcode :value="qrCode" :options="{ width: 100}"></vue-qrcode>
      </div>
    </div>

    <hr/>

    <table style="width: 100%">
      <tr>
        <td style="text-align: center; width: 100%">
            <span style="font-size: 15px">{{ $t("Kiosk.Ticket.Welcome") }}</span
            ><br/>
          <span style="font-size: 18px; font-weight: bold">{{
              business_config.name
            }}</span
          ><br/>
          <span style="font-size: 54px; font-weight: bold">{{
              ticket.public_identifier
            }}</span
          ><br/>
          {{ ticket.checkinDateFormatted }} {{ ticket.checkinTimeFormatted }}
        </td>
      </tr>
    </table>

    <div class="seperator"></div>
    <hr/>
    <table style="width: 100%">
      <tr>
        <td style="text-align: center; width: 50%">
            <span style="font-size: 32px; font-weight: bold">{{
                ticket.total_waiting
              }}</span
            ><br/>
          <span style="font-size: 15px">{{ $t("Kiosk.Ticket.Rank") }}</span>
        </td>
        <td style="text-align: center; width: 50%">
            <span style="font-size: 32px; font-weight: bold">{{
                ticket.waitingTimeFormatted
              }}</span
            ><br/>
          <span style="font-size: 15px">{{
              $t("Kiosk.Ticket.WaitingTime")
            }}</span>
        </td>
      </tr>
    </table>

    <hr/>
    <table style="width: 100%">
      <tr>
        <td style="text-align: left; width: 40%">
            <span style="font-size: 24px; font-weight: bold">{{
                $t("Kiosk.Ticket.Services")
              }}</span
            ><br/>
        </td>
        <td style="text-align: right; width: 60%">
            <span
                v-for="(item, index) in ticket.services"
                :key="index"
                style="font-size: 18px"
            >
              {{ item.title + " " + "(x" + item.qte + ")" }} <br/>
            </span>
        </td>
      </tr>
    </table>

    <footer id="footer" style="margin-top: 10px">
        <span style="font-size: 14px; font-weight: bold">{{
            $t("Kiosk.Ticket.ThankYou")
          }}</span>
    </footer>
  </div>
</template>
<script>
import colorShader from "@/_helpers/color-shader";
import LbrxKioskHeader from "@/components/LbrxKioskHeader.vue";
import TheFooter from "@/components/TheFooter.vue";
import LbrxPhoneNumberInput from "@/components/LbrxPhoneNumberInput.vue";
import LbrxAnonymousTicketPrint from "@/components/LbrxAnonymousTicketPrint.vue";
import LbrxKioskPopup from "@/components/LbrxKioskPopup.vue";
import {kioskService} from "@/_services";
import LbrxKioskTicketPopup from "@/components/LbrxKioskTicketPopup.vue";
import LbrxButton from "@/components/LbrxButton.vue";
import moment from "moment";
import LbrxCreateCustomer from "@/components/LbrxCreateCustomer.vue";
import VueQrcode from "@chenfengyuan/vue-qrcode";
// import jwt = require("jsonwebtoken");

// const secretKey = "9A3CD4E3DF623D8C";

// const payload = { username: "liberrex", password: "tVrXhDAJsh" };

export default {
  name: "LbrxPrintTicketBox",
  components: {
    LbrxCreateCustomer,
    LbrxButton,
    LbrxKioskTicketPopup,
    LbrxKioskPopup, LbrxAnonymousTicketPrint, LbrxPhoneNumberInput, TheFooter, LbrxKioskHeader, VueQrcode
  },
  props: {
    themeTemplate: {required: true, type: Object},
    modelValue: {required: true, type: Boolean},
    services: {required: true, type: Array},
    categories: {required: true, type: Array},
    noAnonym: {required: false, type: Boolean},
    selectedDate: {required: false, type: Object},
    department: {required: false, type: Object},
    agent: {required: false, type: Object},
    directPrint: {required: false, type: Boolean},
    showBarcode: {required: false, type: Boolean, default: false}
  },
  emits: ['validate', 'checkService', 'sending', 'cancel', 'bookingDone', 'resetAll', 'cancel_idle', 'start_idle'],
  data() {
    return {
      showBookingPopup: false,
      currentCustomer: null,
      boxHeight: '40%',
      boxContent: '',
      showTicketPopup: false,
      currentTicket: null,
      currentBooking: null,
      boxIcon: 'loader',
      boxTextColor: '',
      isClosable: false,
      boxBackGround: '#FFFFFF',
      boxIconColor: '',
      showPopup: false,
      showSimConfirmation: false,
      language: 'fr',
      onLine: navigator.onLine,
      showCreateCustomer: false,
      showTermsAndConditions: false,
      kiosk_config: null,
      kiosk_configJson: null,
      business_config: null,
      theme_config: null,
      ticket: {
        checkinDate: "2020-05-12",
        checkinTime: "02:08:20",
        waitingTimeFormatted: "",
        total_waiting: 0,
        public_identifier: "",
        checkinDateFormatted: "",
        checkinTimeFormatted: "",
        services: [],
        unique_id: ""
      },
      arrayServ: [],
      cancelAutoAnonym: false,
      barcode: null
    }
  },
  created() {
    this.boxIconColor = this.themeTemplate.secondaryColor;
    window.addEventListener('online', this.updateOnlineStatus)
    window.addEventListener('offline', this.updateOnlineStatus)
    if (this.directPrint) {
      this.sendAnonymTicket();
    }
    this.kiosk_config = JSON.parse(window.localStorage.getItem('kiosk'));
    this.kiosk_configJson = JSON.parse(JSON.parse(window.localStorage.getItem('kiosk')).config);
    this.business_config = JSON.parse(window.localStorage.getItem('business'));
    this.theme_config = JSON.parse(window.localStorage.getItem('theme'));
  },
  methods: {
    resetAll() {
      this.$emit('resetAll');
      this.arrayServ = [];
      this.showBookingPopup = false;
      this.showTermsAndConditions = false;
      this.showCreateCustomer = false;
      this.showSimConfirmation = false;
      this.showPopup = false;
      this.showBookingPopup = false;
      this.currentCustomer = false;
      this.showTicketPopup = false;
      this.currentTicket = false;
      this.currentBooking = false;
      this.cancelAutoAnonym = false;
    },
    cancelAutoAnonymous() {
      this.cancelAutoAnonym = true;
    },
    cancel() {
      this.$emit('cancel');
    },
    showLoadingPopup() {
      this.showPopup = false;
      this.isClosable = false;
      this.boxIcon = 'loader';
      try {
        this.boxContent = this.$t('Kiosk.generatingTicket');
      } catch (ex) {
        this.boxContent = "Veuillez patienter, nous sommes en train de générer votre ticket";
      }
      this.boxTextColor = this.themeTemplate.primaryColor
      this.boxIconColor = this.themeTemplate.primaryColor;
      this.boxHeight = "40%";
      this.showPopup = true;
    },
    showErrorPopup(errorText) {
      this.showPopup = false;
      this.boxIcon = 'error';
      this.isClosable = true;
      this.boxContent = errorText;
      this.boxTextColor = '#EB5757'
      this.boxIconColor = '#EB5757';
      this.boxHeight = "40%";
      setTimeout(() => {
        this.showPopup = true;
      }, 10)
    },
    showSmsMessagePopup() {
      this.showSimConfirmation = true;
      this.isClosable = true;
    },
    sendAnonymTicket() {
      this.showLoadingPopup();
      this.$emit('sending');
      this.sendTicket(-1, this.getPseudo());
    },
    validateNewCustomer(customer) {
      this.showCreateCustomer = false,
          this.showPopup = true;
      this.currentCustomer.fname = customer.fname;
      this.currentCustomer.lname = customer.lname;
      if (!this.currentCustomer.id) {
        this.createCustomer()
      } else {
        this.updateCustomer();
      }
    },
    updateCustomer() {
      const payload = {fname: this.currentCustomer.fname, lname: this.currentCustomer.lname};
      kioskService.updateCustomer(this.currentCustomer.id, payload)
          .then((res) => {
            if (res.status === 'success') {
              this.sendTicket(res.customer.id, null);
            } else {
              this.showErrorPopup(this.$t('Kiosk.uknownError'));
              this.$emit('sending');
            }
          })
          .catch((err) => {
            this.showErrorPopup(this.$t('Kiosk.uknownError'));
            this.$emit('sending');
          });
    },
    continueProcess() {
      this.showTermsAndConditions = false;
      this.$emit('sending');
      if (!this.noAnonym && !this.selectedDate) {
        this.showPopup = true;
        this.createCustomer();
      } else {
        this.showCreateCustomer = true;
      }
    },
    sendTicketWithNumber(number) {
      if (+this.kiosk_configJson.display_phonenumber_message) {
        if (number.phone_number.charAt(0) == '7' || number.phone_number.charAt(0) == '3') {
          this.showSmsMessagePopup();
          this.$refs.lbrxPhoneNumberInput.clickPad(-1);
          return 0;
        }
      }
      this.$emit('sending');
      this.showLoadingPopup();
      kioskService.searchClient(number)
          .then((res) => {
            if (res.customer.length) {
              this.currentCustomer = res.customer[0];
              if ((this.currentCustomer.fname === null || this.currentCustomer.fname === '' || this.currentCustomer.lname === null || this.currentCustomer.lname === '') && (this.noAnonym && this.selectedDate)) {
                this.showPopup = false;
                this.showCreateCustomer = true;
              } else {
                this.sendTicket(this.currentCustomer.id, null);
              }
            } else {
              this.fireError(number);
            }
          })
          .catch((err) => {
            this.fireError(number);
          })
    },
    fireError(number) {
      this.currentCustomer = {};
      this.currentCustomer.email = "";
      this.currentCustomer.fname = "";
      this.currentCustomer.internal_id = null;
      this.currentCustomer.lname = "";
      this.currentCustomer.phone_number = number.phone_number;
      this.currentCustomer.country_prefix = number.country_prefix;
      this.showPopup = false;
      setTimeout(() => {
        this.$emit('sending');
        this.showTermsAndConditions = true;
      }, 10)
    },
    createCustomer() {
      this.currentCustomer.country = "TN";
      kioskService.createCustomer(this.currentCustomer)
          .then((res) => {
            this.sendTicket(res.customer.id, null);
          })
          .catch((err) => {
            this.showErrorPopup(this.$t('Kiosk.uknownError'));
            this.$emit('sending');
          })
    },
    sendTicketBarcode(barcode) {
      this.barcode = barcode;
      this.showLoadingPopup();
      this.$emit('sending');
      this.sendTicket(-1, this.getPseudo());
    },
    sendTicket(customerId, pseudo) {
      let servicesIds = [];
      let queueId = '';
      let duration = 0;
      this.services.forEach((service) => {
        if (service.checked) {
          queueId = service.queue_id;
          if (service.child && service.child.length) {
            service.child.forEach((chService) => {
              if (chService.checked) {
                duration += chService.duration;
                let idQte = chService.service_id + "-qte:";
                if (chService.qte)
                  idQte += chService.qte
                else
                  idQte += '1'
                servicesIds.push(idQte);
              }
            });
          } else {
            duration += service.duration;
            let idQte = service.service_id + "-qte:";
            if (service.qte)
              idQte += service.qte
            else
              idQte += '1'
            servicesIds.push(idQte);
          }
        }
      });
      if (!this.selectedDate) {
        //join queue
        const payload = {
          customer_id: customerId,
          kiosk_language: window.localStorage.getItem("Language"),
          member_id: -1,
          queue_id: queueId,
          services: servicesIds.toString()
        };
        if (pseudo != null && customerId === -1) {
          payload.pseudo = pseudo;
        }
        if (this.themeTemplate.allowCategory == 1) {
          this.categories.forEach((category) => {
            if (category.checked) {
              payload.category_id = category.id;
            }
          });
        }
        if (this.barcode && this.barcode.length != 0) {
          payload.internal_id = this.barcode;
          this.barcode = null;
        }
        kioskService.joinQueue(payload)
            .then((res) => {
              this.currentTicket = res.ticket;
              this.currentBooking = null;
              this.showPopup = false;
              if (customerId === -1) {
                if (this.themeTemplate.anonymousHibernateInterval != null && this.themeTemplate.anonymousHibernateInterval.status) {
                  window.localStorage.setItem("lastAnonymousTicket", new Date().toString())
                }
                if (JSON.parse(window.localStorage.getItem('kiosk')).allow_print_ticket) {
                  this.printTicket();
                } else if (+this.kiosk_configJson.allow_print_websocket) {
                  this.printTicketWebsocket();
                }
              }
              this.showTicketPopup = true;
            }).catch((err) => {
          if (pseudo != null && customerId === -1) {
            this.showErrorPopup(this.$t('Kiosk.uknownError'));
          } else {
            this.showErrorPopup(this.$t('Kiosk.alreadyHaveTicket'));
          }
          this.$emit('sending');
        })
      } else {
        //create booking
        const payload = {
          user_id: customerId,
          business_id: JSON.parse(window.localStorage.getItem('business')).id,
          services: servicesIds.toString(),
          start_datetime: moment(this.selectedDate.date).locale('en').format('YYYY-MM-DD') + " " + this.selectedDate.time,
          end_datetime: this.getEndDateTime(duration)
        };
        if (this.agent) {
          payload.member_id = this.agent.id;
        } else {
          payload.member_id = null;
        }
        if (this.department) {
          payload.department_id = this.department.id;
        }
        if (this.themeTemplate.allowCategory == 1) {
          this.categories.forEach((category) => {
            if (category.checked) {
              payload.category_id = category.id;
            }
          });
        }
        kioskService.createBooking(payload).then((res) => {
          this.currentBooking = res.booking;
          this.currentTicket = null;
          this.showPopup = false;
          setTimeout(() => {
            this.showBookingPopup = true;
          }, 400);
        }).catch((err) => {
          this.showErrorPopup(this.$t('Kiosk.uknownError'));
        })
      }
    },
    getEndDateTime(duration) {
      let date = moment(this.selectedDate.time, "HH:mm")
          .add(duration, "minutes")
          .format("HH:mm");
      return moment(this.selectedDate.date).locale('en').format("YYYY-MM-DD") + " " + date;
    },
    getPseudo() {
      try {
        switch (JSON.parse(window.localStorage.getItem("kiosk")).pseudo) {
          case "1":
            return this.$t("Kiosk.Pseudos.Customer");
          case "2":
            return this.$t("Kiosk.Pseudos.Patient");
          case "3":
            return this.$t("Kiosk.Pseudos.User");
          default:
            return this.$t("Kiosk.Pseudos.Customer");
        }
      } catch (ex) {
        return "Client";
      }
    },
    printTicket() {
      this.ticket.public_identifier = this.currentTicket.public_identifier;
      this.ticket.checkinDateFormatted = this.currentTicket.checkinDate;
      this.ticket.checkinTimeFormatted = this.currentTicket.checkinTime;
      this.ticket.total_waiting = this.currentTicket.total_waiting;
      this.ticket.services = this.currentTicket.services;
      this.ticket.unique_id = this.currentTicket.unique_id;
      this.ticket.waitingTimeFormatted = this.ticketWaitingTimeFormatted;
      this.ticket.arrivalTimeFormatted = this.ticketArrivalTimeFormatted;
      this.ticket.short_link = this.currentTicket.short_link;
      setTimeout(function () {
        window.print();
      }, 500);
    },
    printTicketWebsocket() {
      console.log(this.currentTicket);

      this.currentTicket.services.forEach(el => {
        this.arrayServ.push(`${el.title} (x${el.qte})`)
      });
      this.printWebsocket();
    },
    connectSocket() {
      //   const token = localStorage.getItem('user'); // Assuming the token is stored in local storage
      const token = 'jwt.sign(payload, secretKey, { algorithm: "HS256" });'

      this.socket = new WebSocket("ws://localhost:8765");

      this.socket.onopen = (event) => {
        this.socket.send(token); // send the token as soon as the connection is established
      };

      this.socket.onmessage = (event) => {
        if (event.data == "Authentication successful") {
          this.sendMessage();
        }
      };

      this.socket.onerror = (error) => {
        console.error(`WebSocket Error: ${error}`);
      };

      this.socket.onclose = (event) => {
        console.log(`WebSocket connection closed: ${event}`);
      };
    },
    sendMessage() {
      if (this.socket.readyState === WebSocket.OPEN) {
        const message = {
          ticket: {
            date: this.currentTicket.checkinDate,
            time: this.currentTicket.checkinTime,
            estimated_waiting_text: this.ticketWaitingTimeFormatted,
            arrival_waiting_text: this.ticketArrivalTimeFormatted,
            total_waiting_text: this.currentTicket.total_waiting,
            title: this.business_config.name,
            welcome_text: this.$t("Kiosk.Ticket.Welcome"),
            totalWaiting_text: this.$t("Kiosk.Ticket.Rank"),
            waitingTime_text: this.$t("Kiosk.Ticket.WaitingTime"),
            arrivalTime_text: this.$t("Kiosk.Ticket.ArrivalTime"),
            services_text: this.$t("Kiosk.Ticket.Services"),
            thankYou_text: this.$t("Kiosk.Ticket.ThankYouGoodBye"),
            token: this.currentTicket.public_identifier,
            services: this.arrayServ,
            footer: "www.liberrex.com",
            qr_code: this.currentTicket.short_link ? this.currentTicket.short_link : 'saudia.tn/t/' + this.currentTicket.unique_id,
            lang: this.$i18n.locale,
            logo: this.kiosk_configJson.photo
          },
          image: ""
        };
        this.socket.send(JSON.stringify(message));
      } else {
        console.error("Socket is not open. Can't send message");
      }
    },
    printWebsocket() {
      this.connectSocket();
    }
  },
  computed: {
    phoneNumberAllowed() {
      return true;
    },
    isDisabled() {
      return this.modelValue;
    },
    makeSecondaryColorLighter() {
      return colorShader.methods.newShade(this.themeTemplate.secondaryColor, 50);
    },
    getBackground() {
      if (this.noAnonym)
        return this.themeTemplate.printTicket.background;
      if (this.themeTemplate.printTicket.backgroundImage.includes('base64')) {
        return `url(${this.themeTemplate.printTicket.backgroundImage})`;
      }
      return this.themeTemplate.printTicket.background;
    },
    getTextColor() {
      if (!this.noAnonym && this.themeTemplate.printTicket.backgroundImage.includes('base64'))
        return this.themeTemplate.textColor;
      return this.themeTemplate.printTicket.textColor;
    },
    ticketWaitingTimeFormatted() {
      return moment()
          .startOf("day")
          .add(this.currentTicket.waiting_time, "seconds")
          .format("HH:mm");
    },
    ticketArrivalTimeFormatted() {
      return moment()
          .add(this.currentTicket.waiting_time, "seconds")
          .format("HH:mm");
    },
    qrCode() {
      if (this.ticket.short_link) {
        return this.ticket.short_link;
      } else {
        return process.env.VUE_APP_TICKET_URL + this.ticket.unique_id;
      }
    }
  },
  mounted() {
    this.isClosable = true;
  }
}
</script>

<style scoped>
.center-content {
  display: flex !important;
  justify-content: center !important;
}

.printTicketTitle {
  margin-top: 5vmin;
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 2.5vw;
  line-height: 3vw;
  text-align: left;
  color: v-bind(getTextColor);
}

.agreement {
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 300;
  font-size: 1.2vw;
  line-height: 3vw;
  text-align: left;
  margin-top: 1.5vmin;
  color: v-bind(getTextColor);
}

.or-div {
  font-family: 'Exo 2', serif;
  font-style: normal;
  font-weight: 300;
  font-size: 2vw;
  line-height: 2vw;
  /* identical to box height */
  margin-top: 4vh;
  margin-bottom: 6vh;
  text-align: center !important;
  color: v-bind(getTextColor);
}

.left-container {
  height: 100%;
}

.anon-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 57vh !important;
}

.right-container {
  height: 100%;
  padding-left: 2vmin;
  padding-right: 5vmin;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer-container {
  width: 100% !important;
  height: auto;
}

.mainContainer {
  padding: 4vh 2vw;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  display: flex;
  width: 100% !important;
}

.printTicketBox {
  background: v-bind(getBackground) no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  flex-direction: column;
  justify-content: space-between;
}
</style>